<template>
  <v-navigation-drawer
    right
    v-model="isOpen"
    fixed temporary
    :width="$vuetify.breakpoint.xs ? 350 : 500"
    :permanent="dirty"
  >
    <template v-slot:prepend>
      <v-toolbar color="color2 color2Text--text" tile dense>
        <v-toolbar-title>Round Order</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          fab
          small
          @click.stop="cancel(true)"
        >
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
    </template>

    <template v-slot:append>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row dense>
          <v-col cols="6">
            <v-btn
              color="success white--text"
              @click.stop="onSaveClick"
              :disabled="!dirty"
              :loading="loading"
              block
              class="py-9"
            >
              Save
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="error white--text"
              @click.stop="cancel"
              :disabled="loading"
              block
              class="py-9"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>

    <v-card-text v-if="tournament">
      <v-container class="pt-10 px-0">
        <v-row dense v-for="d in iData" :key="d.id">
          <v-col cols="12">
            <v-card>
              <v-card-text class="text-left">
                <h2>{{ d.name }}</h2>
                <v-divider></v-divider>
                <div v-for="(r, i) in d.rounds" :key="r.id">
                  <div :class="{ 'font-weight-black': r.number !== r.numberOg } ">{{ r.name }}</div>
                  <v-btn
                    v-if="i < d.rounds.length - 1"
                    color="color3" text small
                    @click.stop="swap(d.id, r.id)"
                  >
                  <v-icon small>fas fa-long-arrow-up</v-icon>
                      <v-icon small>fas fa-long-arrow-down</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

  </v-navigation-drawer>
</template>

<script>
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'

export default {
  props: ['tournament'],
  data () {
    return {
      isOpen: false,
      iData: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    dirty () {
      return this.dto && this.dto.length > 0
    },
    dto () {
      const changed = []
      this.iData.forEach(d => {
        d.rounds.forEach(r => {
          if (r.number !== r.numberOg) {
            changed.push({ id: r.id, number: r.number })
          }
        })
      })
      return changed
    }
  },
  methods: {
    onSaveClick () {
      this.loading = true
      console.log(this.dto)
      this.$VBL.round.reorder(this.tournament.id, this.dto)
        .then(r => this.cancel())
        .catch(e => {
          console.log(e.response)
          this.error = e.response.data
        })
        .finally(() => { this.loading = false })
    },
    init () {
      this.iData = this.tournament.publicDivisions.map(d => {
        return {
          id: d.id,
          name: d.name,
          rounds: d.rounds.map(r => {
            return {
              id: r.id,
              number: r.number,
              numberOg: r.number,
              name: r.name,
              published: r.published
            }
          })
        }
      })
    },
    swap (dId, rId) {
      const d = this.iData.find(f => f.id === dId)
      const r1 = d.rounds.find(f => f.id === rId)
      const r2 = d.rounds.find(f => f.number === r1.number + 1)
      r1.number += 1
      r2.number -= 1
      d.rounds.sort(firstBy('number'))
    },
    cancel (close) {
      this.isOpen = false
    },
    open () {
      this.isOpen = true
    }
  },
  watch: {
    isOpen: function (val) {
      if (!val) {
        this.cancel()
      } else {
        this.init()
      }
    }
  }
}
</script>
